/**
 * Helper to insert elements into array of error object.
 * @param arr
 * @param index
 * @param newItem
 */
export const insert = (arr, index, newItem) => {
  if (arr == null) {
    arr = [];
  }
  arr[index] = newItem;
  return arr;
};

const phones = {
  "ar-LB": /^(\+?961)?(3|7\d|81)\d{6}$/, //Lebanon
  "en-PH": /^(09|\+639)\d{9}$/, //Phillipines
  "es-BO": /^(\+591)?[67]\d{7}$/, //Bolivia
  "es-PE": /^(\+?51)?9\d{8}$/, //Peru
  "ka-GE": /^(\+?995)?(5\d|79)\d{7}$/, //Georgia
  "uz-UZ": /^(\+?998)?(6[125-79]|7[1-69]|88|9\d)\d{7}$/, //Uzbekistan
};

/**
 * Helper to validate mobile phone which validator.js is not supported now
 * @param str
 */
export const isMobilePhone = (str: string) => {
  for (const key in phones) {
    if (phones.hasOwnProperty(key)) {
      const phone = phones[key];
      if (phone.test(str)) {
        return true;
      }
    }
  }
  return false;
};
