import {
  SA_CSV_FILE_UPLOAD,
  SA_CSV_FILE_UPLOAD_SUCCESS,
  SA_CSV_FILE_UPLOAD_FAIL,
  SA_CSV_FILE_UPLOAD_RESET,
  SA_CSV_FILE_SUBMIT,
  SA_CSV_FILE_SUBMIT_SUCCESS,
  SA_CSV_FILE_SUBMIT_FAIL,
} from "./UpdateSARegistrationStatusActionType";
import {
  ISubmitCsvFileFail,
  ISubmitCsvFileSuccess,
  IUploadCsvFileFail,
  IUploadCsvFileSuccess,
  UpdateSARegistrationActionTypes,
} from "./UpdateSARegistrationStatusAction";
import { IUpdateSARegistrationStatusState } from "./IUpdateSARegistrationStatusState";

const INITIAL_STATE: IUpdateSARegistrationStatusState = {
  uploadCSVFileRequest: {
    CsvBase64: "",
  },
  submitCSVFileRequest: {
    fileId: "",
  },
  isUploading: false,
  errorMessage: "",
  validationResponse: {
    isValid: false,
    fileId: "",
    errors: [],
    summary: [],
  },
  submitResponse: {
    submitted: false,
  },
};

/**
 * Updates state of fetch upload Csv File.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
const applyUploadCsv = (
  state: IUpdateSARegistrationStatusState
): IUpdateSARegistrationStatusState => ({
  ...state,
  uploadCSVFileRequest: {
    ...state.uploadCSVFileRequest,
  },
  isUploading: true,
  errorMessage: "",
});

/**
 * Updates state of fetch upload Csv File success.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @param {IUploadCsvFileSuccess} action The action state.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
const applyUploadCsvSuccess = (
  state: IUpdateSARegistrationStatusState,
  action: IUploadCsvFileSuccess
): IUpdateSARegistrationStatusState => ({
  ...state,
  validationResponse: action.validationResponse,
  isUploading: false,
  errorMessage: "",
});

/**
 * Updates state of fetch upload Csv File fail.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @param {IUploadCsvFileFail} action The action state.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
const applyUploadCsvFail = (
  state: IUpdateSARegistrationStatusState,
  action: IUploadCsvFileFail
): IUpdateSARegistrationStatusState => ({
  ...state,
  isUploading: false,
  errorMessage: action.errorMessage,
});

const applyUploadCsvReset = (): IUpdateSARegistrationStatusState => INITIAL_STATE;

/**
 * Updates state of fetch Submit Csv File.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
const applySubmitCsv = (
  state: IUpdateSARegistrationStatusState
): IUpdateSARegistrationStatusState => ({
  ...state,
  submitCSVFileRequest: {
    ...state.submitCSVFileRequest,
  },
  isUploading: true,
  errorMessage: "",
});

/**
 * Updates state of fetch Submit Csv File success.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @param {ISubmitCsvFileSuccess} action The action state.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
const applySubmitCsvSuccess = (
  state: IUpdateSARegistrationStatusState,
  action: ISubmitCsvFileSuccess
): IUpdateSARegistrationStatusState => ({
  ...state,
  submitResponse: action.submitResponse,
  isUploading: false,
  errorMessage: "",
});

/**
 * Updates state of fetch Submit Csv File Fail.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @param {ISubmitCsvFileFail} action The action state.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
const applySubmitCsvFail = (
  state: IUpdateSARegistrationStatusState,
  action: ISubmitCsvFileFail
): IUpdateSARegistrationStatusState => ({
  ...state,
  isUploading: false,
  errorMessage: action.errorMessage,
});

/**
 * Reducer function for Activity component.
 * @param {IUpdateSARegistrationStatusState} state The activity state.
 * @param {IUpdateSARegistrationStatusActionType} action The action type.
 * @returns {IUpdateSARegistrationStatusState} The activity state.
 */
function UpdateSARegistrationStatusReducer(
  state = INITIAL_STATE,
  action: UpdateSARegistrationActionTypes
): IUpdateSARegistrationStatusState {
  switch (action.type) {
    case SA_CSV_FILE_UPLOAD:
      return applyUploadCsv(state);
    case SA_CSV_FILE_UPLOAD_SUCCESS:
      return applyUploadCsvSuccess(state, action);
    case SA_CSV_FILE_UPLOAD_FAIL:
      return applyUploadCsvFail(state, action);
    case SA_CSV_FILE_UPLOAD_RESET: {
      return applyUploadCsvReset();
    }
    case SA_CSV_FILE_SUBMIT:
      return applySubmitCsv(state);
    case SA_CSV_FILE_SUBMIT_SUCCESS:
      return applySubmitCsvSuccess(state, action);
    case SA_CSV_FILE_SUBMIT_FAIL:
      return applySubmitCsvFail(state, action);
    default:
      return state;
  }
}

export default UpdateSARegistrationStatusReducer;
