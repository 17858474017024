/**
 * The Window property screen returns a reference to the screen object associated with the window.
 */

export const WINDOW_SCREEN = window.screen;
export const MIN_PRIMARY_COLUMN_WIDTH = 600;
export const MIN_SECONDARY_COLUMN_WIDTH = 320;
export const COL_GAP = 24;
export const NAVBAR_WIDTH = WINDOW_SCREEN ? 80 : 72;
// add 144 (72 * 2) to account for the left side nav
export const MEDIA_BREAK =
  MIN_PRIMARY_COLUMN_WIDTH + MIN_SECONDARY_COLUMN_WIDTH + COL_GAP + NAVBAR_WIDTH * 2;
export const LARGE_DESKTOP_WIDTH = 1920;

export const BREAKPOINTS = {
  xsmall: 375, // mobile
  small: 575.98, // large mobile
  medium: 767.98, // tablet
  highMedium: 1024, // laptop
  large: 1440, // average desktop or large laptop
  xlarge: 1920, // large desktop
};
