import { ITag, TagPicker } from "office-ui-fabric-react";
import React, { ReactElement } from "react";
import FormikErrorMessage from "../FormikErrorMessage/FormikErrorMessage";
import { newItem, existingItem, FullWidth } from "./FormikPicker.styles";

interface ITagExtended {
  isNewItem: string;
}

interface ISuggestedItems {
  isNewItem: object;
  existingItem: object;
  key: string;
  name: string;
}

/* TODO - Update styles to work properly or remove

const baseStyles = {
  root: {
    height: 48,
    marginTop: 7,
    marginBottom: 20,
  },
  input: {
    fontSize: FontSizes.xLargePlus,
    lineHeight: FontSizes.xxLargePlus,
  },
  text: {
    padding: "8px 11px",
  },
};

*/

/**
 * Formik Picker.
 * TODO: Picker does not appear to show initial selected value.
 * @component
 * @returns {ReactElement} - formik picker.
 */
const FormikPicker = ({ field, form: { touched, errors }, ...props }): ReactElement => {
  /**
   * Checks if the list contains an item in the picker.
   * @param {ITag} tag - the tag.
   * @param {ITag[]} tagList  - the tag list.
   * @returns {boolean} - value indicating true or false.
   */
  const _listContains = (tag: ITag, tagList?: ITag[]): boolean => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }

    return tagList.filter((compareTag) => compareTag.key === tag.key).length > 0;
  };

  /**
   * Called when the typed in text has changed.
   * @param {string} filterText - the string to filter on.
   * @param {ITag[]} tagList - the list to filter against.
   * @returns {Array} of elements to display.
   */
  const _onFilterChanged = (filterText: string, tagList: ITag[]): ITag[] => {
    const existingMatches = filterText
      ? props.suggestedItems
          .filter((tag) => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0)
          .filter((tag) => !_listContains(tag, tagList))
      : [];

    if (props.allowTypedInputItemResult) {
      return existingMatches.some((a) => a.key === filterText)
        ? existingMatches
        : [{ key: filterText, name: filterText, isNewItem: true } as ITag].concat(existingMatches);
    }
    return existingMatches;
  };

  /**
   * Suggestions to render to the user.
   * @param {ISuggestedItems} props - the list of suggested items.
   * @returns {ReactElement} the suggestions.
   */
  const _onRenderSuggestionsItem = (props: ISuggestedItems): ReactElement => {
    return (
      <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        {props.name}
      </div>
    );
  };

  /**
   * When an Itme is selected.
   * @param {ITag} item - the item selected.
   * @returns {ITag} the selected Item.
   */
  const _onItemSelected = (item: ITag & ITagExtended): ITag | null => {
    //TODO: look at dispatching action to add new school.
    return item;
  };

  return (
    <FullWidth>
      <TagPicker
        onRenderSuggestionsItem={_onRenderSuggestionsItem}
        onItemSelected={_onItemSelected}
        removeButtonAriaLabel={props.removeButtonAriaLabel}
        onResolveSuggestions={_onFilterChanged}
        getTextFromItem={(item: ITag): string => item.name}
        pickerSuggestionsProps={props.pickerSuggestionsProps}
        itemLimit={props.itemLimit}
        inputProps={props.inputProps}
        onChange={[field.onChange]}
        resolveDelay={props.resolveDelay}
        selectedItems={props.selectedItems}
        styles={props.styles}
        {...field}
        {...props}
      />
      <FormikErrorMessage name={field.name} />
    </FullWidth>
  );
};

export default FormikPicker;
