import React, { useEffect } from "react";

import { delay } from "lodash";

import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";

import {
  benefitLabelStyles,
  containerStyles,
  horizontalGapStackTokens,
  removeButtonStyles,
} from "Components/ProfileVisualStudioBenefitDetails/VisualStudioBenefitDetailSidePanel.styles";
import {
  buttonStyles,
  messageBarStyles,
  panelHeaderStyles,
} from "Components/SidePanel/SidePanel.styles";
import { BenefitNameEnum } from "Constants/BenefitConstants";
import { OperationStatus } from "Constants/OperationStatus";

import { IVisualStudioBenefitDetailSidePanelProps } from "./IVisualStudioBenefitDetailSidePanelProps";

const VisualStudioBenefitDetailSidePanel = (
  props: IVisualStudioBenefitDetailSidePanelProps
): React.ReactElement => {
  const {
    isProvisioned,
    currentStatus,
    canEnableBenefits,
    assignBenefitClicked,
    extendBenefitClicked,
    removeBenefitClicked,
    getProfileBenefits,
    claimBenefitReset,
    errorMessage,
    operationStatus,
    isPanelOpen,
    setIsPanelOpen,
  } = props;

  const dismissPanel = (): void => {
    setIsPanelOpen(false);
    claimBenefitReset();
  };

  useEffect(() => {
    if (operationStatus === OperationStatus.Succeeded) {
      // We need to delay here because the VS Partner API does not always immediately reflect the update.
      delay(getProfileBenefits, 2000);
      delay(dismissPanel, 2000);
    }
  }, [operationStatus === OperationStatus.Succeeded]);

  const isSaving =
    operationStatus === OperationStatus.Pending || operationStatus === OperationStatus.Succeeded;

  const onRenderHeaderContent = (): React.ReactElement => (
    <Text styles={panelHeaderStyles}>Manage Benefit</Text>
  );

  const onRenderFooterContent = (): React.ReactElement => (
    <>
      {!!errorMessage ? (
        <MessageBar messageBarType={MessageBarType.warning} styles={messageBarStyles}>
          {errorMessage}
        </MessageBar>
      ) : null}
      <div>
        <Separator />
        {!isProvisioned && canEnableBenefits && (
          <PrimaryButton
            text="Enable"
            styles={buttonStyles}
            onClick={assignBenefitClicked}
            disabled={isSaving}
          />
        )}
        {isProvisioned && (
          <PrimaryButton
            styles={{ ...buttonStyles, ...removeButtonStyles }}
            text="Remove"
            onClick={removeBenefitClicked}
            disabled={isSaving}
            color="red"
          />
        )}
        {isProvisioned && (
          <PrimaryButton
            text="Extend"
            onClick={extendBenefitClicked}
            styles={buttonStyles}
            disabled={isSaving}
          />
        )}
        <DefaultButton onClick={dismissPanel} styles={buttonStyles} disabled={isSaving}>
          Cancel
        </DefaultButton>
      </div>
    </>
  );

  return (
    <Panel
      isOpen={isPanelOpen}
      title="Manage Benefit"
      closeButtonAriaLabel="Close"
      onRenderHeader={onRenderHeaderContent}
      onRenderFooterContent={onRenderFooterContent}
      onDismiss={dismissPanel}
      type={PanelType.medium}
      isFooterAtBottom={true}
    >
      <Stack>
        <Text>You can claim a benefit and assign to active users</Text>
      </Stack>
      <Stack styles={containerStyles}>
        <Stack horizontal tokens={horizontalGapStackTokens}>
          <StackItem>
            <Text styles={benefitLabelStyles}>Benefit:</Text>
          </StackItem>
          <StackItem>
            <Text>{BenefitNameEnum.VisualStudio}</Text>
          </StackItem>
        </Stack>
        <Stack horizontal tokens={horizontalGapStackTokens}>
          <StackItem>
            <Text styles={benefitLabelStyles}>Current Status:</Text>
          </StackItem>
          <StackItem>
            <Text>{currentStatus}</Text>
          </StackItem>
        </Stack>
      </Stack>
    </Panel>
  );
};

export default VisualStudioBenefitDetailSidePanel;
