import { mergeStyles } from "office-ui-fabric-react/lib/Styling";
import styled from "styled-components";

/**
 * New item in picker.
 */
export const newItem = mergeStyles({
  color: "#f00",
  background: "#ddf",
  padding: "10px",
});

export const FullWidth = styled.div`
  width: 100%;

  input {
    width: 100%;
  }
`;

/**
 * Existing item in picker.
 */
export const existingItem = mergeStyles({ color: "#222", padding: "10px" });
