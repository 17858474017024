/**
 * The Window property screen returns a reference to the screen object associated with the window.
 */

import { IBasePickerSuggestionsProps } from "@fluentui/react";

/**
 * The suggestion props for the NormalPeoplePicker.
 */
export const peoplePickerSuggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested People",
  mostRecentlyUsedHeaderText: "Suggested Contacts",
  noResultsFoundText: "No results found",
  loadingText: "Loading",
  showRemoveButtons: true,
  suggestionsAvailableAlertText: "People Picker Suggestions available",
  suggestionsContainerAriaLabel: "Suggested contacts",
};
