import React from "react";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { ColumnContent } from "../Layout/Layout";
import FormikErrorMessage from "../FormikErrorMessage/FormikErrorMessage";

/**
 * HoC for Formik Toggle
 * @param param0
 */
const FormikToggle = ({
  field, /// { name, value, onChange, onBlur },
  form, /// { touched, errors, values }, // also values , setXXX, handleXXX< dirty, isValid, status, etc
  ...props
}) => (
  <ColumnContent>
    <Toggle
      label={props.label}
      styles={props.styles}
      inlineLabel
      onChange={props.onChange}
      checked={field.value}
      {...field}
      {...props}
    />
    <FormikErrorMessage name={field.name} />
  </ColumnContent>
);
export default FormikToggle;
