import { IKeyText } from "../Interfaces/IKeyText";

export const languages: IKeyText[] = [
  { key: "Afar", text: "Afar" },
  { key: "Afrikaans", text: "Afrikaans" },
  { key: "Aghem", text: "Aghem" },
  { key: "Akan", text: "Akan" },
  { key: "Albanian", text: "Albanian" },
  { key: "Amharic", text: "Amharic" },
  { key: "Arabic", text: "Arabic" },
  { key: "Arabic, Egyptian", text: "Arabic, Egyptian" },
  { key: "Arabic, Gulf", text: "Arabic, Gulf" },
  { key: "Arabic, Levantine", text: "Arabic, Levantine" },
  { key: "Armenian", text: "Armenian" },
  { key: "Assamese", text: "Assamese" },
  { key: "Asturian", text: "Asturian" },
  { key: "Asu", text: "Asu" },
  { key: "Azerbaijani (Cyrillic)", text: "Azerbaijani (Cyrillic)" },
  { key: "Azerbaijani (Latin)", text: "Azerbaijani (Latin)" },
  { key: "Bafia", text: "Bafia" },
  { key: "Bamanankan (Latin)", text: "Bamanankan (Latin)" },
  { key: "Bangla", text: "Bangla" },
  { key: "Basaa", text: "Basaa" },
  { key: "Bashkir", text: "Bashkir" },
  { key: "Basque", text: "Basque" },
  { key: "Belarusian", text: "Belarusian" },
  { key: "Bemba", text: "Bemba" },
  { key: "Bena", text: "Bena" },
  { key: "Blin", text: "Blin" },
  { key: "Bodo", text: "Bodo" },
  { key: "Bosnian (Cyrillic)", text: "Bosnian (Cyrillic)" },
  { key: "Bosnian (Latin)", text: "Bosnian (Latin)" },
  { key: "Breton", text: "Breton" },
  { key: "Bulgarian", text: "Bulgarian" },
  { key: "Burmese", text: "Burmese" },
  { key: "Cantonese (Traditional)", text: "Cantonese (Traditional)" },
  { key: "Catalan", text: "Catalan" },
  { key: "Cebuano", text: "Cebuano" },
  {
    key: "Central Atlas Tamazight (Arabic)",
    text: "Central Atlas Tamazight (Arabic)",
  },
  {
    key: "Central Atlas Tamazight (Latin)",
    text: "Central Atlas Tamazight (Latin)",
  },
  {
    key: "Central Atlas Tamazight (Tifinagh)",
    text: "Central Atlas Tamazight (Tifinagh)",
  },
  { key: "Central Kurdish", text: "Central Kurdish" },
  { key: "Chechen", text: "Chechen" },
  { key: "Cherokee (Cherokee)", text: "Cherokee (Cherokee)" },
  { key: "Chiga", text: "Chiga" },
  { key: "Chinese (Simplified)", text: "Chinese (Simplified)" },
  { key: "Chinese (Traditional)", text: "Chinese (Traditional)" },
  { key: "Church Slavic", text: "Church Slavic" },
  { key: "Colognian", text: "Colognian" },
  { key: "Congo Swahili", text: "Congo Swahili" },
  { key: "Cornish", text: "Cornish" },
  { key: "Corsican", text: "Corsican" },
  { key: "Croatian", text: "Croatian" },
  { key: "Czech", text: "Czech" },
  { key: "Danish", text: "Danish" },
  { key: "Dari", text: "Dari" },
  { key: "Divehi", text: "Divehi" },
  { key: "Dogri", text: "Dogri" },
  { key: "Duala", text: "Duala" },
  { key: "Dutch", text: "Dutch" },
  { key: "Dzongkha", text: "Dzongkha" },
  { key: "Edo", text: "Edo" },
  { key: "Embu", text: "Embu" },
  { key: "English", text: "English" },
  { key: "Esperanto", text: "Esperanto" },
  { key: "Estonian", text: "Estonian" },
  { key: "Ewe", text: "Ewe" },
  { key: "Ewondo", text: "Ewondo" },
  { key: "Faroese", text: "Faroese" },
  { key: "Fijian", text: "Fijian" },
  { key: "Filipino", text: "Filipino" },
  { key: "Finnish", text: "Finnish" },
  { key: "French", text: "French" },
  { key: "Friulian", text: "Friulian" },
  { key: "Fulah", text: "Fulah" },
  { key: "Galician", text: "Galician" },
  { key: "Ganda", text: "Ganda" },
  { key: "Georgian", text: "Georgian" },
  { key: "German", text: "German" },
  { key: "Greek", text: "Greek" },
  { key: "Greenlandic", text: "Greenlandic" },
  { key: "Guarani", text: "Guarani" },
  { key: "Gujarati", text: "Gujarati" },
  { key: "Gusii", text: "Gusii" },
  { key: "Haitian", text: "Haitian" },
  { key: "Hausa (Latin)", text: "Hausa (Latin)" },
  { key: "Hawaiian", text: "Hawaiian" },
  { key: "Hebrew", text: "Hebrew" },
  { key: "Hindi", text: "Hindi" },
  { key: "Hmong Daw", text: "Hmong Daw" },
  { key: "Hungarian", text: "Hungarian" },
  { key: "Icelandic", text: "Icelandic" },
  { key: "Igbo", text: "Igbo" },
  { key: "Inari Sami", text: "Inari Sami" },
  { key: "Indonesian", text: "Indonesian" },
  { key: "Interlingua", text: "Interlingua" },
  {
    key: "Inuktitut (Canadian Aboriginal Syllabics)",
    text: "Inuktitut (Canadian Aboriginal Syllabics)",
  },
  { key: "Inuktitut (Latin)", text: "Inuktitut (Latin)" },
  { key: "Invariant Language", text: "Invariant Language" },
  { key: "Irish", text: "Irish" },
  { key: "isiXhosa", text: "isiXhosa" },
  { key: "isiZulu", text: "isiZulu" },
  { key: "Italian", text: "Italian" },
  { key: "Japanese", text: "Japanese" },
  { key: "Javanese (Latin)", text: "Javanese (Latin)" },
  { key: "Jola-Fonyi", text: "Jola-Fonyi" },
  { key: "Kâ€™icheâ€™", text: "Kâ€™icheâ€™" },
  { key: "Kabuverdianu", text: "Kabuverdianu" },
  { key: "Kabyle", text: "Kabyle" },
  { key: "Kako", text: "Kako" },
  { key: "Kalenjin", text: "Kalenjin" },
  { key: "Kamba", text: "Kamba" },
  { key: "Kannada", text: "Kannada" },
  { key: "Kanuri", text: "Kanuri" },
  { key: "Kanuri", text: "Kanuri" },
  { key: "Kashmiri (Devanagari)", text: "Kashmiri (Devanagari)" },
  { key: "Kashmiri (Perso-Arabic)", text: "Kashmiri (Perso-Arabic)" },
  { key: "Kazakh", text: "Kazakh" },
  { key: "Khmer", text: "Khmer" },
  { key: "Kikuyu", text: "Kikuyu" },
  { key: "Kinyarwanda", text: "Kinyarwanda" },
  { key: "Kiswahili", text: "Kiswahili" },
  { key: "Konkani", text: "Konkani" },
  { key: "Korean", text: "Korean" },
  { key: "Koyra Chiini", text: "Koyra Chiini" },
  { key: "Koyraboro Senni", text: "Koyraboro Senni" },
  { key: "Kutchi", text: "Kutchi" },
  { key: "Kwasio", text: "Kwasio" },
  { key: "Kyrgyz", text: "Kyrgyz" },
  { key: "Lakota", text: "Lakota" },
  { key: "Langi", text: "Langi" },
  { key: "Lao", text: "Lao" },
  { key: "Latvian", text: "Latvian" },
  { key: "Lingala", text: "Lingala" },
  { key: "Lithuanian", text: "Lithuanian" },
  { key: "Low German", text: "Low German" },
  { key: "Lower Sorbian", text: "Lower Sorbian" },
  { key: "Luba-Katanga", text: "Luba-Katanga" },
  { key: "Lule Sami", text: "Lule Sami" },
  { key: "Luo", text: "Luo" },
  { key: "Luxembourgish", text: "Luxembourgish" },
  { key: "Luyia", text: "Luyia" },
  { key: "Macedonian", text: "Macedonian" },
  { key: "Machame", text: "Machame" },
  { key: "Maithili", text: "Maithili" },
  { key: "Makhuwa-Meetto", text: "Makhuwa-Meetto" },
  { key: "Makonde", text: "Makonde" },
  { key: "Malagasy", text: "Malagasy" },
  { key: "Malay (Latin)", text: "Malay (Latin)" },
  { key: "Malayalam", text: "Malayalam" },
  { key: "Maltese", text: "Maltese" },
  { key: "Mandarin Chinese", text: "Mandarin Chinese" },
  { key: "Manipuri", text: "Manipuri" },
  { key: "Manx", text: "Manx" },
  { key: "Maori", text: "Maori" },
  { key: "Mapudungun", text: "Mapudungun" },
  { key: "Marathi", text: "Marathi" },
  { key: "Masai", text: "Masai" },
  { key: "Mazanderani", text: "Mazanderani" },
  { key: "Meru", text: "Meru" },
  { key: "MetaÊ¼", text: "MetaÊ¼" },
  { key: "Mohawk", text: "Mohawk" },
  { key: "Mongolian (Cyrillic)", text: "Mongolian (Cyrillic)" },
  {
    key: "Mongolian (Traditional Mongolian)",
    text: "Mongolian (Traditional Mongolian)",
  },
  { key: "Montenegrin (Cyrillic)", text: "Montenegrin (Cyrillic)" },
  { key: "Montenegrin (Latin)", text: "Montenegrin (Latin)" },
  { key: "Morisyen", text: "Morisyen" },
  { key: "Mundang", text: "Mundang" },
  { key: "Nâ€™Ko", text: "Nâ€™Ko" },
  { key: "Nama", text: "Nama" },
  { key: "Nepali", text: "Nepali" },
  { key: "Ngiemboon", text: "Ngiemboon" },
  { key: "Ngomba", text: "Ngomba" },
  { key: "North Ndebele", text: "North Ndebele" },
  { key: "Northern Kurdish (Arabic)", text: "Northern Kurdish (Arabic)" },
  { key: "Northern Luri", text: "Northern Luri" },
  { key: "Northern Sami", text: "Northern Sami" },
  { key: "Norwegian BokmÃ¥l", text: "Norwegian BokmÃ¥l" },
  { key: "Norwegian Nynorsk", text: "Norwegian Nynorsk" },
  { key: "Nuer", text: "Nuer" },
  { key: "Nyankole", text: "Nyankole" },
  { key: "Occitan", text: "Occitan" },
  { key: "Odia", text: "Odia" },
  { key: "Oromo", text: "Oromo" },
  { key: "Osage (Latin)", text: "Osage (Latin)" },
  { key: "Osage (Osage)", text: "Osage (Osage)" },
  { key: "Ossetic (Cyrillic)", text: "Ossetic (Cyrillic)" },
  { key: "Ossetic (Latin)", text: "Ossetic (Latin)" },
  { key: "Papiamento", text: "Papiamento" },
  { key: "Pashto", text: "Pashto" },
  { key: "Persian", text: "Persian" },
  { key: "Polish", text: "Polish" },
  { key: "Portuguese", text: "Portuguese" },
  { key: "Portuguese (Brazil)", text: "Portuguese (Brazil)" },
  { key: "Portuguese (Portugal)", text: "Portuguese (Portugal)" },
  { key: "Prussian", text: "Prussian" },
  { key: "Pseudo", text: "Pseudo" },
  { key: "Punjabi (Arabic)", text: "Punjabi (Arabic)" },
  { key: "Punjabi (Gurmukhi)", text: "Punjabi (Gurmukhi)" },
  { key: "Quechua", text: "Quechua" },
  { key: "QuerÃ©taro Otomi", text: "QuerÃ©taro Otomi" },
  { key: "Romanian", text: "Romanian" },
  { key: "Romansh", text: "Romansh" },
  { key: "Rombo", text: "Rombo" },
  { key: "Rundi", text: "Rundi" },
  { key: "Russian", text: "Russian" },
  { key: "Rwa", text: "Rwa" },
  { key: "Saho", text: "Saho" },
  { key: "Sakha", text: "Sakha" },
  { key: "Samburu", text: "Samburu" },
  { key: "Samoan", text: "Samoan" },
  { key: "Sango", text: "Sango" },
  { key: "Sangu", text: "Sangu" },
  { key: "Sanskrit", text: "Sanskrit" },
  { key: "Santali (Devanagari)", text: "Santali (Devanagari)" },
  { key: "Scottish Gaelic", text: "Scottish Gaelic" },
  { key: "Sena", text: "Sena" },
  { key: "Serbian (Cyrillic)", text: "Serbian (Cyrillic)" },
  { key: "Serbian (Latin)", text: "Serbian (Latin)" },
  { key: "Sesotho", text: "Sesotho" },
  { key: "Sesotho sa Leboa", text: "Sesotho sa Leboa" },
  { key: "Setswana", text: "Setswana" },
  { key: "Shambala", text: "Shambala" },
  { key: "Shona", text: "Shona" },
  { key: "Sindhi (Arabic)", text: "Sindhi (Arabic)" },
  { key: "Sindhi (Devanagari)", text: "Sindhi (Devanagari)" },
  { key: "Sinhala", text: "Sinhala" },
  { key: "siSwati", text: "siSwati" },
  { key: "Skolt Sami", text: "Skolt Sami" },
  { key: "Slovak", text: "Slovak" },
  { key: "Slovenian", text: "Slovenian" },
  { key: "Soga", text: "Soga" },
  { key: "Somali (Latin)", text: "Somali (Latin)" },
  { key: "South Ndebele", text: "South Ndebele" },
  { key: "Southern Sami", text: "Southern Sami" },
  { key: "Spanish", text: "Spanish" },
  {
    key: "Standard Moroccan Tamazight (Tifinagh)",
    text: "Standard Moroccan Tamazight (Tifinagh)",
  },
  { key: "Swedish", text: "Swedish" },
  { key: "Swiss German", text: "Swiss German" },
  { key: "Syriac", text: "Syriac" },
  { key: "Tachelhit (Latin)", text: "Tachelhit (Latin)" },
  { key: "Tachelhit (Tifinagh)", text: "Tachelhit (Tifinagh)" },
  { key: "Tahitian", text: "Tahitian" },
  { key: "Taita", text: "Taita" },
  { key: "Tajik (Cyrillic)", text: "Tajik (Cyrillic)" },
  { key: "Tamil", text: "Tamil" },
  { key: "Tasawaq", text: "Tasawaq" },
  { key: "Tatar (Cyrillic)", text: "Tatar (Cyrillic)" },
  { key: "Telugu", text: "Telugu" },
  { key: "Teso", text: "Teso" },
  { key: "Thai", text: "Thai" },
  { key: "Tibetan", text: "Tibetan" },
  { key: "Tigre", text: "Tigre" },
  { key: "Tigrinya", text: "Tigrinya" },
  { key: "Tongan", text: "Tongan" },
  { key: "Turkish", text: "Turkish" },
  { key: "Turkmen (Latin)", text: "Turkmen (Latin)" },
  { key: "Ukrainian", text: "Ukrainian" },
  { key: "Upper Sorbian", text: "Upper Sorbian" },
  { key: "Urdu", text: "Urdu" },
  { key: "Uyghur (Arabic)", text: "Uyghur (Arabic)" },
  { key: "Uzbek (Cyrillic)", text: "Uzbek (Cyrillic)" },
  { key: "Uzbek (Latin)", text: "Uzbek (Latin)" },
  { key: "Uzbek (Perso-Arabic)", text: "Uzbek (Perso-Arabic)" },
  { key: "Vai (Latin)", text: "Vai (Latin)" },
  { key: "Vai (Vai)", text: "Vai (Vai)" },
  { key: "Valencian", text: "Valencian" },
  { key: "Venda", text: "Venda" },
  { key: "Vietnamese", text: "Vietnamese" },
  { key: "VolapÃ¼k", text: "VolapÃ¼k" },
  { key: "Vunjo", text: "Vunjo" },
  { key: "Walser", text: "Walser" },
  { key: "Welsh", text: "Welsh" },
  { key: "Western Frisian", text: "Western Frisian" },
  { key: "Wolaytta", text: "Wolaytta" },
  { key: "Wolof", text: "Wolof" },
  { key: "Xitsonga", text: "Xitsonga" },
  { key: "Yangben", text: "Yangben" },
  { key: "Yi", text: "Yi" },
  { key: "Yiddish", text: "Yiddish" },
  { key: "Yoruba", text: "Yoruba" },
  { key: "Yucatec Maya", text: "Yucatec Maya" },
  { key: "Zarma", text: "Zarma" },
];

export const localeLanguages: IKeyText[] = [
  { key: "en-US", text: "English" },
  { key: "ko-KR", text: "한국어" },
  { key: "ja-JP", text: "日本語" },
  { key: "de-DE", text: "Deutsch" },
  { key: "ru-RU", text: "Русский" },
  { key: "zh-TW", text: "中文 (繁體)" },
  { key: "zh-CN", text: "中文 (简体)" },
  { key: "pt-BR", text: "Português Brasileiro" },
  { key: "es-ES", text: "Español" },
  { key: "fr-FR", text: "Français" },
  { key: "it-IT", text: "Italiano" },
];
