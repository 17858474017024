import React from "react";
import { Dropdown } from "office-ui-fabric-react";
import FormikErrorMessage from "../FormikErrorMessage/FormikErrorMessage";

/**
 * Office UI dropdown for use in formik forms with multiselect.
 * @param {field} field property.
 * @param {form} form property.
 * @param {props} passed in props from the parent component.
 * @returns {React.ReactElement} Dropdown multi select component.
 */
const FormikMultiDropdown = (props: any): React.ReactElement => {
  const { field } = props;

  return (
    <div>
      <Dropdown
        selectedKeys={field.value}
        onChange={[field.onChange]}
        multiSelect
        options={[props.options]}
        styles={props.styles}
        {...field}
        {...props}
      />
      <FormikErrorMessage name={field.name} />
    </div>
  );
};

export default FormikMultiDropdown;
